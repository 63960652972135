<template>
  <div class="element-div">
    <div
      class="flex card-content-item attr-box"
      v-for="(ele, index) in elementData.list"
      :key="index"
    >
      <div class="flex prop-box">
        <div class="text_hidden propname">
          {{ ele.name }}
        </div>

        <div class="flex propvalue">
          <el-select
            v-if="ele.type == 'select'"
            v-model="ele.value"
            placeholder="请选择"
            @change="changeArrow(ele, index)"
            @visible-change="val => visbleChageArrow(ele, index, val)"
          >
            <el-option
              v-for="(item, idx) in ele.list"
              :key="idx"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <div
            class="input-item-box"
            v-else
            @dblclick="onShowInput(ele)"
            :class="{ p15: !ele.showInput || ele.disable }"
          >
            <template v-if="ele.disable">
              {{ ele.value }}
            </template>
            <el-input
              v-else-if="ele.showInput && !ele.disable"
              v-model="ele.value"
              placeholder="请输入内容"
              :disabled="ele.disable"
              :readonly="ele.disable"
              ref="input"
              @keyup.enter.native="entryInput(ele)"
              @blur="() => entryInput(ele)"
              @input="ele.value = checkGreaterZero(ele.value, ele.typeName)"
            ></el-input>
            <template v-else>
              {{ ele.value }}
            </template>
          </div>
        </div>
      </div>
      <!-- 选择自定义箭头块 -->
      <el-dialog
        title="选择自定义箭头块"
        :visible.sync="showArrow"
        width="580px"
        :append-to-body="true"
        :close-on-click-modal="false"
        @close="showArrow = false"
        v-dialogDrag
        v-disable-contextmenu
      >
        <div class="dialog-body-box">
          <div class="block-wrapper" v-stopdrag>
            <div classs="block-title" style="padding-bottom:8px;">
              从图层块中选择:
            </div>

            <el-select
              v-model="arrowData"
              style="margin-right: 10px;margin-bottom: 0px;width:100%;"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in arrowList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
        </div>
        <div class="flex-center block-footer" slot="footer">
          <el-button
            @click="showArrow = false"
            size="nomal"
            native-type="button"
            >取消</el-button
          >
          <el-button @click="saveArrow" type="primary" size="nomal"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "LTHatch",
  props: {
    pixiMap: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    featureDatas: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      elementData: this.resetData(),
      showArrow: false,
      arrowIndex: -1,
      arrowList: [],
      arrowData: ""
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 数据赋值
    initData(datas) {
      let _styleList = Array.from(this.pixiMap.dwgservice._dimStyle.keys()).map(
        it => {
          return {
            name: it,
            value: it
          };
        }
      );
      let jtArrs = [
        {
          name: "无",
          value: "无"
        },
        {
          name: "用户箭头",
          value: "用户箭头"
        }
      ];
      let arrowLists = Array.from(this.pixiMap.dwgservice.getDimBlock().keys());
      let arrowValues = Array.from(
        this.pixiMap.dwgservice.getDimBlock().values()
      );
      if (arrowLists && arrowLists.length > 0) {
        arrowLists.map((item, index) => {
          jtArrs.unshift({
            name: item,
            value: arrowValues[index]
          });
        });
      }
      if (datas.length == 1) {
        let it_geometry = this.pixiMap.select_command._select_features[0];
        this.elementData.list.map(item => {
          if (item.sname == "_arrow1") {
            item.list = jtArrs;
            item.value = it_geometry
              .getGeometry()
              .subData()
              .getBlock1();
          } else if (item.sname == "_arrow2") {
            item.list = jtArrs;
            item.value = it_geometry
              .getGeometry()
              .subData()
              .getBlock2();
          } else if (item.sname == "_arrow_size") {
            item.value = it_geometry
              .getGeometry()
              .subData()
              .getDimBlockSacle();
          } else {
            item.value = this.$returnTypeName(item.sname, datas[0]);
            if (item.typeName && item.typeName == "number" && item.value)
              item.value = Number(item.value).toFixed(3);
            else if (item.sname == "_style") {
              item.value = this.$returnTypeName(item.sname, datas[0]);
              item.list = _styleList;
            }
          }
          return item;
        });
      } else {
        this.elementData.list.map(item => {
          let list = [],
            isMore = false;

          datas.some(it => {
            if (item.sname == "_arrow1") {
              item.list = jtArrs;
              list.push(it.subData().getBlock1());
            } else if (item.sname == "_arrow2") {
              item.list = jtArrs;
              list.push(it.subData().getBlock2());
            } else if (item.sname == "_arrow_size") {
              list.push(it.subData().getDimBlockSacle());
            } else {
              list.push(this.$returnTypeName(item.sname, it));
              if (item.typeName && item.typeName == "number" && item.value)
                list.push(Number(item.value).toFixed(3));
              else if (item.sname == "_style") {
                list.push(this.$returnTypeName(item.sname, it));
                item.list = _styleList;
              }
            }
            if (list.length >= 2 && Array.from(new Set(list)).length > 1) {
              item.value = "多种";
              isMore = true;
              return true;
            }
          });
          if (!isMore) item.value = list[0];
          return item;
        });
      }
    },
    // 重置数据
    resetData() {
      return {
        name: "标注",
        sname: "LTDimension",
        type: "moreInput",
        show: false,
        value: "",
        list: [
          {
            name: "箭头1",
            value: "",
            sname: "_arrow1",
            list: [],
            type: "select",
            typeName: ""
          },
          {
            name: "箭头2",
            value: "",
            sname: "_arrow2",
            list: [],
            type: "select",
            typeName: ""
          },
          {
            name: "箭头大小",
            value: "",
            sname: "_arrow_size",
            type: "input",
            typeName: "number"
          },
          {
            name: "标注样式",
            value: "",
            sname: "_style",
            list: [],
            type: "select",
            typeName: ""
          },

          {
            name: "文本大小",
            value: "",
            sname: "rtext._height",
            type: "input",
            typeName: "number"
          },
          {
            name: "标注内容",
            value: "",
            sname: "_rtext._text",
            type: "input",
            typeName: "text"
          },
          {
            name: "文本x坐标",
            value: "",
            sname: "_rtext.position.x",
            type: "input",
            typeName: "number"
          },
          {
            name: "文本y坐标",
            value: "",
            sname: "_rtext.position.y",
            type: "input",
            typeName: "number"
          }
        ]
      };
    },
    // 规定typeName为number表单只能输入数字、小数点和负号
    checkGreaterZero(value, type) {
      if (type == "number") value = value.replace(/[^\-?\d.]/g, "");
      return value;
    },
    // 双击显示表单
    onShowInput(item) {
      if (item && item.disable) return;
      if (!item.showInput) {
        this.$set(item, "showInput", true);
        this.$nextTick(() => {
          this.$refs.input[0].focus();
        });
      } else item.showInput = false;
    },
    // 表单entry
    entryInput(ele) {
      this.$emit("entryInput", ele);
    },
    visbleChageArrow(item, index, val) {
      if (!val) {
        if (item.value.indexOf("用户") > -1) {
          this.arrowIndex = index;
          this.showArrow = true;
          let blocks = this.pixiMap.dwgservice._blocks;
          this.arrowList = Array.from(blocks.keys()).map(item => {
            return item;
          });
          this.arrowData = "";
        }
      }
    },
    saveArrow() {
      this.elementData.list[this.arrowIndex].value = this.arrowData;
      this.showArrow = false;
      this.$emit("setArrowValue", this.elementData.list[this.arrowIndex]);
    },
    // 标注箭头切换
    changeArrow(item, index) {
      if (item.sname.indexOf("_arrow") > -1) {
        this.arrowIndex = index;
        if (item.value.indexOf("用户") > -1) {
          this.showArrow = true;
          let blocks = this.pixiMap.dwgservice._blocks;
          this.arrowList = Array.from(blocks.keys()).map(item => {
            return item;
          });
          this.arrowData = "";
        } else {
          this.$emit("setArrowValue", item);
        }
      } else this.entryInput(item);
    }
  },
  watch: {
    featureDatas: {
      handler(newVal, oldVal) {
        this.elementData = this.resetData();
        if (newVal && newVal.length > 0 && oldVal != newVal) {
          this.initData(newVal);
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'

.element-div
  position relative
  width 100%
  height 100%
</style>
